@media (min-width: 992px){
  .service-title{
    padding: 0px;
  }
  .service-type svg {
    margin-bottom: 25px;
  }

}

@media (max-width: 991px){
  body, .short-text{
    font-size: 14px;
  }
  .title {
    font-size: 36px;
  }
  .name {
    font-size: 15px;
  }
  .nav > li > a {
    padding-right: 10px;
    padding-left: 10px;
  }

  .partner-wrap{
    margin: 0 auto;
  }
  .partner-col .opal, .partner-col .ecolines, .partner-col .rn, .partner-col .rta{
    width: 100%;
    height: 100px;
    margin-bottom: 30px;
  }
  .service-type {
    margin-bottom: 80px;
  }
  .exigen-img1{
    text-align: center;
    padding-top: 20px;
  }

  #clients{
    div.bx-wrapper{
      max-width: 700px !important;
    }
    .client-slide{
      width: 700px;
    }
  }

  .top-info{
    .title {
      font-size: 32px;
    }
  }
  .center-xs{
    width: initial;
    margin: 0 auto;
    float: none;
    display: table;
  }

  #zeimuls .zeimuls-structure-item {
    width: 220px;
  }
  #slider, #slider .bx-wrapper, #slider .bx-viewport, #slider .inner-bg{
    height: 620px;
  }

  #slider .slider-container {
    height: 750px;
  }
  .bxslider{
    height: 620px;
    overflow: hidden;
  }


  .team-member{
    margin-bottom: 20px;
  }
  .contact-info {
    margin-bottom: 10px;
  }
  #services {
    padding-bottom: 0px;
  }

  .navbar img.logo {
    max-width: 77px;

  }
}
@media (max-width: 767px){
  .case-studies {
    max-width: 300px;
    margin: 0 auto;
    display: block;
    float: none;
  }

  .bx-wrapper .bx-controls-direction a {

    width: 67px;
    height: 67px;
  }
  .team{
    margin-bottom: 15px;
  }

  #opal-bop{
    .title{
      text-align: center;
    }
  }

  .ipad-wrap{
    text-align: center;
    img{
      float: none;
    }
  }

  #slider{
    display: none;
  }
  .navbar-default {
    background-color: $darker-color;
    border-color: transparent;
  }
  .orange-bg{
    .navbar-default {
      background-color: #ff895e;;
    }
  }
  .navbar-default .navbar-nav > li > a {
    color: #fff;
  }
  .navbar-nav {
    margin: 7.5px 0px;
  }
  .navbar-brand {
    float: left;
    padding: 10px 30px;
  }
  .screen{
    text-align: center;
    padding-bottom: 0px;
  }
  #info-graphic{
    display: none;
  }

  #clients{
    div.bx-wrapper{
      max-width: 500px !important;
    }
    .client-slide{
      width: 500px;
    }
    .client-slide.active .client-slide-wrap{
      padding-top: 60px;
      padding-right: 40px;
      padding-left: 40px;
    }
    .client-container{
      padding-top: 0px;

      background-size: 300% 100%;
      padding-bottom: 30px;
    }
  }
  .circle {
    width: 50px;
    height: 50px;
  }

  .top-info .title {
    font-size: 28px;
  }
  .opex-info-pic{
    position: absolute;
  }
  .center-xs{
    text-align: center;
  }
  #opal-bop .excerpt-text{
    font-size: 16px;
  }
  #opal-bop{
    .quote-row{
      img{
        margin-left: 15px;
      }
    }
  }

  .sticky {
    height: 54px;
  }

  .navbar img.logo {
    max-width: 90px;
    max-height: 30px;
  }
  .service-title {
    font-size: 16px;
  }

  .service-type svg {
    width: 60px;
    height: 60px;
  }

  .border-bottom-gray {
    padding-bottom: 20px;
    margin-bottom: 80px;
  }
  #company .container {
    padding-bottom: 80px;
  }
  #projects {
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) {
  #exigen {
    .brief-project-info {
      width: 230px;
      padding-left: 40px;
    }
  }
  .small-title {
    margin-top: 50px;
  }
  #info-graphic-sm {
    display: none;
  }
}

@media (min-width: 600px)and (max-width: 767px){
  .service-type{
    margin-left: 25%;
    width: 50%;
  }
}

@media (max-width: 680px){
  .table-cell:nth-last-child(1) {
    border-top: 2px solid rgb(255, 223, 27);
  }
}
@media (max-width: 600px){
  #clients{
    div.bx-wrapper {
      max-width: 400px !important;
      .bx-controls-direction a {
        width: 50px;
        height: 50px;
        img{
          top: 10px;
        }
      }
      .bx-prev img {
        left: 8px;
      }
      .bx-next img {
        left: 13px;
      }
      .bx-prev:active {
        margin-top: -14px;
        left: -29px;
      }
      .bx-next:active {
        margin-top: -14px;
        right: -32px;
      }
    }
    .client-slide {
      width: 400px;
      height: 350px;
    }
    .client-slide.active {
      height: 400px;
    }
     .client-position {
      margin-bottom: 20px;
    }
    .client-slide.active .client-slide-wrap {
      padding-top: 30px;
      padding-right: 40px;
      padding-left: 40px;
    }
  }


  #opal-bop {
    .excerpt-text {
      font-size: 13px;
    }
    .quote {
      padding-left: 25px;
    }
    #clients .client-position, #clients .client-description{
      font-size: 13px;
    }
  }

}
@media (max-width: 530px){
  #zeimuls .zeimuls-structure-item {
    height: initial;
    margin-bottom: 50px;
  }
}

@media (max-width: 480px){

  .service-type {
    padding-left: 0px;
    padding-right: 0px;
  }
  #clients{
    div.bx-wrapper {
      max-width: 350px !important;
    }
    .client-slide {
      width: 350px;
      height: 350px;
    }
    .client-slide.active {
      min-height: 400px;
    }
    #clients .client-position, #clients .client-description{
      font-size: 12px;
    }
    .more-txt {
      font-size: 20px;
      padding: 0 10px;
    }
    .client-position, .client-description{
      font-size: 12px;
    }
  }
}
@media (max-width: 400px){
  body, .short-text {
    font-size: 12px;
  }
  .title {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .short-text {
    margin-bottom: 40px;
  }
  .service-type svg{
    width: 50px;
    height: 50px;
  }
  .service-title {
    font-size: 16px;
  }
  .title-divider {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .more-txt {
    font-size: 15px;
    padding: 0px 10px;
  }
  .more {
    margin-top: 0px;
  }
  .border-bottom-gray {
    padding-bottom: 0;
  }
  .team {
    width: 260px;
    height: 260px;
  }
  .valery, .nicolay, .edgar {
    width: 205px;
    height: 205px;
    margin-bottom: 10px;
  }
  .team-member {
    margin-bottom: 30px;
  }

  .top-info .title {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .technology-column{
    width: 100%;
  }

  .brochure-text{
    width: 212px;
    line-height: 50px;
    span {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .brochure-icon{
    width: 50px;
    height: 50px;
    .pdf-icon.pdf-icon {
      width: 22px;
      height: 22px;
      margin: 14px;
    }
  }
}
@media (max-width: 350px){
  #clients{
    .client-slide {
      width: initial;
    }
    .bx-wrapper{
      .bx-controls-direction a {
        width: 40px;
        height: 40px;
      }
      .bx-next{
        right: 0px;
      }
      .bx-next:active, .bx-next:hover{
        right: -1px;
      }
      .bx-prev {
        left: 0px;
      }
      .bx-prev:active, .bx-prev:hover{
        left: -1px;
      }
    }
  }
  .studie.shine:after {
    opacity: 1;
    top: -40%;
    left: -40%;
  }
}

@media(max-width: 400px){
  .brochure-text:active{
    .brochure-btn-active {
      padding-top: 29px;
      padding-left: 34px;
    }
  }

  .brochure-text {
    font-size: 12px;
  }
}

@media(max-width: 768px){
  .title-mobile {
    margin-top: 40px;
  }
}