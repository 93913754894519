.consultation{
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  a{
    cursor: pointer;
    color: $darker-color;
    div{
      border-radius: 20px;
      line-height: 1.67;
      letter-spacing: 0.5px;
      display: inline-block;
      padding: 5px 18px;
      margin-left: 17px;
      img, svg{
        width: 26px;
        height: 26px;
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
}
@media (max-width: 767px){
  .consultation {
    font-size: 16px;
    a div {
      display: block;
      margin: 0 auto;
      margin-top: 10px;
      width: 260px;
    }
  }
}