
.opex-about-bg
{
  background: linear-gradient(45deg, #206d87 0%,#39a7d1 59%);
}

.logo-wrap
{
  height: 85px;
  width: 260px;
  border: 1px solid #dadada;
  position: relative;
  margin-bottom: 5%;
  margin-top: 5%;
  display: inline-block;
}

.logo-wrap > img
{
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#opal-bop{
  .top-info{
    background: url(../images/opal/Rectangle-13.jpg) center center no-repeat;
    background-size: cover;

    .top-img{
      text-align: right;
    }
  }
  #layer1{
    background-image: url(../images/opal/layer1.png);
    padding-bottom: 5%;
  }
  .map{
    margin-bottom: 20px;
  }
  .quote-row{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    position: relative;
    border: 1px solid #d7d7d7;
    margin-bottom: 50px;
    margin-top: 50px;
    width: 100%;
    img{
      float: left;
      width: 60px;
      height: 57px;
      margin-bottom: 10px;
      margin-left: 50px;
    }
  }
  .quote-row:before, .quote-row:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 100px;
    left: 10%;
    width: 100%;
    top: 80%;
    max-width: 80%;
    background: #777;
    -webkit-box-shadow: 0 35px 20px #777;
    -moz-box-shadow: 0 35px 20px #777;
    box-shadow: 0px 0 50px 10px #BBB8B8;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  .quote-row:after
  {
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    right: 10%;
    left: auto;
  }
  .quote {
    display: inline-block;
    padding-left: 50px;
    padding-bottom: 10px;
  }
  .excerpt-text {
    display: inline-block;
    font-style: italic;
    font-size: 18px;
    padding-left: 5%;
    padding-top: 12px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  .shadow-bot-top {
    position: relative;
    background-color: white;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .shadow-bot-top:before, .shadow-bot-top:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 20px rgba(161, 151, 151, 1);
    -moz-box-shadow: 0 0 20px rgba(161, 151, 151, 1);
    box-shadow: 0 0 20px rgba(161, 151, 151, 1);
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
    -moz-border-radius: 10px;
    border-radius: 40%;
  }
  .shadow-bot-top span {
    color: #6dcff6;
    margin-right: 20px;
  }
}
#opal-bop {
  margin-bottom: 100px;
  .title {
    margin-top: 50px;
    margin-bottom: 37px;
  }
  .intro{
    line-height: 1.88;
    letter-spacing: .4px;
  }
  .sprockets{
    text-align: center;
  }
  .light-blue-bg{
    padding: 50px;
  }
  .light-blue-bg:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 55px 55px 0;
    border-style: solid;
    border-color: #f8f8f8 #d7e6ec;
  }
  .pic{
    text-align: center;
  }
  .spacer{
    margin-top: 30px;
    margin-bottom: 50px;
  }
  img{
    max-width: 100%;
  }
}

