@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "variables";
@import "mixins";
@import "buttons";
@import "menu";
@import "partials/slider";
@import "partials/consultation";
@import "partials/services";
@import "partials/industries";
@import "partials/technology";
@import "partials/clients";
@import "partials/case_studies";


@font-face {
  font-family: 'Montserrat-Light';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Light'), local('Montserrat-Light'),
  url(../fonts/montserrat/montserrat-light.woff) format('woff'),
  url(../fonts/montserrat/montserrat-light.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Hairline';
  src: url('../fonts/montserrat/Montserrat-Hairline.otf');
  src: url('../fonts/montserrat/Montserrat-Hairline.eot?');
  src: url('../fonts/montserrat/Montserrat-Hairline.eot?#iefix') format('embedded-opentype');

  src: url('../fonts/montserrat/Montserrat-Hairline.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../fonts/montserrat/Montserrat-Bold.otf');
  src: url('../fonts/montserrat/montserrat-bold-webfont.eot');
  src: url('../fonts/montserrat/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/montserrat/montserrat-bold-webfont.svg#webfont') format('svg');
  src: url('../fonts/montserrat/montserrat-bold-webfont.ttf');
  src: url('../fonts/montserrat/montserrat-bold-webfont.woff') format('woff');

}

@font-face {
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 400;

  src: url('../fonts/Open_Sans/OpenSans-Regular.otf');
  src: url('../fonts/Open_Sans/OpenSans-Regular.eot');
  src: url('../fonts/Open_Sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/Open_Sans/OpenSans-Regular.svg#webfont') format('svg');
  src: url('../fonts/Open_Sans/OpenSans-Regular.woff2') format('woff2');
  src: url('../fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('../fonts/montserrat/Montserrat-ExtraBold.otf');
}

//@font-face {
//  font-family: 'icomoon';
//  src:url('../fonts/icomoon/icomoon.eot?-rdnm34');
//  src:url('../fonts/icomoon/icomoon.eot?#iefix-rdnm34') format('embedded-opentype'),
//  url('../fonts/icomoon/icomoon.woff?-rdnm34') format('woff'),
//  url('../fonts/icomoon/icomoon.ttf?-rdnm34') format('truetype'),
//  url('../fonts/icomoon/icomoon.svg?-rdnm34#icomoon') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

html, body {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: 400;
  font-family: 'Montserrat-Light';
  font-size: 16px;
  overflow-x: hidden;
  color: $darker-color;
}
a, a:hover{
  outline: 0;
  text-decoration: none;
  outline-color: unset;
  text-decoration-color: unset;
}
@media (min-width: 1400px){
  .container {
    width: 1240px;
  }
}
ul, menu, dir {
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-padding-start: 0px;
}

img{border:0}

.bold{
  font-weight: 700 !important;
}
.not-active, .not-active:hover{
  pointer-events: none !important;;
  cursor: default;
}
.container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
  margin-right: 0px;
  margin-left: 0px;
}
.white{
  color: white;
}
.yellow{
  color: $yellow-color2;
}
.orange{
  color: #ff895e;
}
.red2{
  color: $red-color2;
}
.bright-red{
  color: #d90f3f;
}
.light-black{
  color: rgb(55, 55, 55);
}
.green{
  color: #37a13d;
}
.green2{
  color: #40a66b;
}
.brown-green{
  color: rgb( 74, 103, 13 );
}
.green-bg{
  background-color: rgba(52, 160, 61, 0.851);
}
.green-bg2{
  background-color: #40a66b;
}
.white-bg{
  background: white;
}
.light-blue-bg{
  position: relative;
  background-color: #f8f8f8;
}
.lighter-blue-bg{
  background: #038bc1;
}
.blue-bg{
  background: #506df9;
}
.blue-gradient-bg{
  background: #0e65c2;
}
.dark-blue{
  color: $dark-blue-color;
}
.yellow-bg{
  background:#f4c631;
}
.bright-red-bg{
  background-color: #d90f3f;
}
.red-bg{
  background-color: $red-color;
}
.red-bg2{
  background-color: $red-color2;
}
.gray-bg{
  background-color: $gray-color;
}
.darker-bg{
  background-color:$darker-color;
}
.dark-blue-bg{
  background-color: $dark-blue-color;
}
.common-color{
  color: #0a151e;
}
.light-green-bg{
  background-color: rgb( 235, 246, 203 );
}
.sphere{
  border-radius: 50%;
}
.container-fluid{
  padding: 0px;
}
.clear-pads{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.clear-margs{
  margin: 0!important;
}
.title{
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 1.2px;
  margin-bottom: 20px;
}
.line{
  margin-top: 60px; margin-bottom: 60px; border-bottom: 1px solid #efefef; width: 100%;
}
.left{
  float: left;
}
.right{
  float: right;
}
.short-text{
  margin: 0 auto;
  margin-bottom: 86px;
  max-width: 613px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.88;
  letter-spacing: 0.4px;
}



.inner-bg{
  position: relative;
  height: 765px;
  width: 100%;
}
.centred{
  max-width: 600px;
}
.center{
  width: 100%;
  text-align: center;
}

.service-type svg{
  width: 85px;
  height: 85px;
}

.title-divider{
  width: 40px;
  height: 2px;
  background: #e21d1a;
  margin-top: 27px;
  margin-bottom: 25px;
}

.name{
  font-size: 18px;
}

#vacansy-form, #contact-form{
  .more-btn-pressed, .more-btn-hover{
    display: none;
  }
  .more-btn-pressed{
    background: url(../images/more-pressed.png);
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position-x: 2px;
    background-position-y: 2px;
  }
  .more-btn-hover{
    background: url(../images/more-hover.png) center center no-repeat;
    background-size: 100% 100%;
  }
  .more-btn {
    background: url(../images/more.png) center center no-repeat;
    background-size: 100% 100%;
    vertical-align: middle;
    display: table-cell;
  }

}
#vacansy-form button:hover, #contact-form button:hover {
  .more-btn{
    display: none;
  }
  .more-btn-hover{
    display: table-cell;
  }
}
#vacansy-form button:active, #contact-form button:active  {
  .more-btn-hover, .more-btn{
    display: none;
  }
  .more-btn-pressed{
    display: table-cell;
  }
}

.more {
  display: inline-block;
}

.more a{
  display: table;
  color: black;
}

.border-bottom-gray{
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 55px;
  margin-bottom: 80px;
}
.holder{
  position: absolute;
  width: 270px;
  height: 280px;
  top: 100px;
  left: 5%;
  display: none;
}
.coffee-holder{
  z-index: 1;
  position: absolute;
  width: 251px;
  height: 188px;
  right: 7%;
  .cofe{
    position: absolute;
    top: 0px;
    max-width: 251px;
    max-height: 188px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .pencil{
    position: absolute;
    bottom: -98px;
    left: 47px;
  }
}



.navbar-placeholder{
  margin-top: 100px;
}
@media(max-width: 768px){
  .navbar-placeholder {
    margin-top: 0;
  }
}

.valery, .nicolay, .edgar{
  width: 290px;
  height: 290px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.valery{
  background: url(../images/company/valery.png) center center no-repeat;
  background-size: contain;
}
.nicolay{
  background: url(../images/company/nicolay.png) center center no-repeat;
  background-size: contain;
}
.edgar{
  background: url(../images/company/edgar.png) center center no-repeat;
  background-size: contain;
}
.contact-info{
  margin-top: 20px;
}
.pin, .pin2, .email, .phone, .fax{
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
}
.pin:before{
  content: '';
  position: absolute;
  top: 4px;
  left: 0px;
  background: url( ../images/pin.png) center center no-repeat;
  height: 17px;
  width: 12px;
}
.email:before{
  content: '';
  position: absolute;
  top: 5px;
  left: 0px;
  background: url( ../images/email.png) center center no-repeat;
  height: 12px;
  width: 17px;
}
.phone:before{
  content: '';
  position: absolute;
  top: 3px;
  left: 0px;
  background: url( ../images/phone.png) center center no-repeat;
  height: 15px;
  width: 15px;
}
.fax:before{
  content: '';
  position: absolute;
  top: 2px;
  left: -2px;
  background: url( ../images/fax.png) center center no-repeat;
  height: 19px;
  width: 19px;
}

.contact-form-input:last-of-type{
  margin-bottom: 50px;
}

#company{
  padding-top: 100px;
  .rotate-right{
    -webkit-transform: skew(0deg, 5deg);
    -o-transform:   skew(0deg, 5deg);
    -ms-transform:   skew(0deg, 5deg);
    -transform: skew(0deg, 5deg);
    background-color: #f4c631;
    padding-top: 60px;
    margin-bottom: 60px;
  }

  .normalize-rotation{
    -webkit-transform: skew(0deg, -5deg);
    -o-transform:   skew(0deg, -5deg);
    -ms-transform:   skew(0deg, -5deg);
    -transform: skew(0deg, -5deg);

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .container{
    padding-top: 15px;
    padding-bottom: 95px;
  }
}
//#career table{
//  width: 100%;
//}
.soc-icons{
  text-align: center;
  margin-top: 50px;
  margin-bottom: 45px;
}

.soc-icons a:not(:last-of-type){
  margin-right: 40px;
}
.footer-info{
  color: white;
  text-align: center;
}
.contact-info, .footer-info{
  font-family: 'Open Sans Regular';
}

//.vacansy{
//  border-bottom: 1px solid #e3e3e3;
//  th{
//    height: 40px;
//    vertical-align: top;
//  }
//  td{
//    line-height: 1.6;
//    padding-top: 20px;
//    padding-bottom: 20px;
//    padding-right: 15px;
//    position: relative;
//  }
//  tr:not( :last-of-type ){
//    td:after{
//      content: "";
//      height: 2px;
//      background-color: #02f19c;
//      width: 30px;
//      position: absolute;
//      bottom: 0px;
//      left: 0px;
//    }
//  }
//}
//.vacansy:last-of-type{
//  margin-bottom: 100px;
//}
.plus{
  height: 70px;
  width: 22px;
  float: right;
  display: inline-block;
}
.carrer-name{
  display: inline-block;
  line-height: 70px;
  color: #000;
  width: 96%;
  width: calc( 100% - 22px );
}

.categories{
  margin-bottom: 70px;
}
.category-wrap, .category{
  display: inline-block;
}
.categories .category:not(:first-of-type){
  margin-left: 1.5em;
}
.category.active{
  font-weight: bold;
}
.category{
  cursor: pointer;
  color: cadetblue;
  font-size: 13px;
}
.case-studies .image, .category{
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all  .4s;
}
.sticky {
  position: fixed;
  width: 100%;
  height: 64px;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  border-top: 0;
  background: #0a151e;
  border-bottom: gray solid 1px;
  animation: navShow .4s;
}
.orange-bg.sticky{
  background: #ff895e;
}

.title, .custom-software-bg, .design-bg, .ecolines-bg, .city{
  transition: all .4s;
}
.opal-bg{
  transition: all .6s;
}
.year{
  -webkit-transition: all .4s cubic-bezier(0.465, 0.615, 0.370, 1.235);
  -moz-transition: all .4s cubic-bezier(0.465, 0.615, 0.370, 1.235);
  -o-transition: all .4s cubic-bezier(0.465, 0.615, 0.370, 1.235);
  transition: all  .4s cubic-bezier( 0.465, 0.615, 0.370, 1.15 );
}

.text{
  transition: all .8s;
}
.year{
  -webkit-transition: all 1s cubic-bezier(0.000, 1.025, 0.580, 1.000);
  -moz-transition: all 1s cubic-bezier(0.000, 1.025, 0.580, 1.000);;
  -o-transition: all 1s cubic-bezier(0.000, 1.025, 0.580, 1.000);;
  transition: all  1s cubic-bezier(0.000, 1.025, 0.580, 1.000);
}
.year-txt{
  @include animate( all, 1.5s, cubic-bezier(0.000, 1.025, 0.580, 1.000) );
}
.image.active {
  @include animate( all, 1.3s, cubic-bezier(0.000, 1.025, 0.580, 1.000) );
}

@keyframes slideInFromLeft {
  0% {    margin-left: -1000px; }
  50% {margin-left: 20px;}
  75% {margin-left: -5px;}
  100% {     margin-left: 0px;}
}
@keyframes slideOutFromLeft {
  0%   { margin-left: 0px;}
  50%  { margin-left: 20px;}
  100% { margin-left: -1000px; }
}

@keyframes navShow {
  0% {
    transform: translate3d(0,-100px,0);
    -webkit-transform: translate3d(0,-100px,0);
    -moz-transform: translate3d(0,-100px,0);
    -ms-transform: translate3d(0,-100px,0);
    -o-transform: translate3d(0,-100px,0);
  }
  100% {
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
  }
}

.brief-project-info{
  div{
    height: 40px;
  }
  margin-bottom: 50px;
}

.padd-top-50{
  padding-top: 50px;
}

.text-center {
  text-align: center !important;
}

.team-member-name {
  font-size: 21px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-align: center;
  color: #212121;
  margin-bottom:0px;
  margin-top:0px;
}

.team-member-role {
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: 0.4px;
  text-align: center;
  color: #575757;
  margin-bottom:0px;
  margin-top:0px;
}
.caseStudyGradient{
  background: linear-gradient(0deg,rgba(226, 29, 26,1),rgba(226, 29, 26,0))bottom center no-repeat;
  background-size: 100% 82%;
  height: 367px;
}
@import "liaa";
@import "pages/zeimuls";
@import "pages/opal";
@import "pages/case_styles";
@import "pages/museum";
@import "pages/exigen";
@import "footer";
@import "medium";
@import "small";
