ul.bxslider {
  padding-left: 0;
  li{
    list-style: none;
  }
}
.bx-wrapper .bx-viewport {
  box-shadow: none;
  border: none;
  left: 0px;
}

#slider{
  overflow: hidden;
  .slider-container{
    height: 765px;
  }
  .bxslider ul li{
    overflow: hidden;
  }
  .title {
    font-size: 40px;
  }
  .short-text{
    font-size: 18px;
  }
  .bx-pager{
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    float: none;
    position: relative;
    bottom: 100px;
    text-align: left;
  }
  .bx-wrapper .bx-pager.bx-default-pager a{
    background: transparent;
    height: 20px;
    text-indent: 0;
    &.active{
      background: transparent;
    }

    .wrap{
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      height: 20px;
      width: 100%;
      align-items: center;
    }
    .background{
      height: 3px;
      width: 100%;
      background: #fff;
    }
  }

  .bx-viewport{
    max-width: 1920px;
    margin: 0 auto;
    overflow: visible !important;
  }
}


@media (min-width: 768px) {
  #slider{
    margin-top: -100px;
  }
}


@keyframes clientSlideIn {
  0% { left: 800px;}
  100% { left: 0px;}
}
@keyframes quoteSlideIn {
  0% {background-position-x: 200%;}
  100% {background-position-x: 10%;}
}

.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
  bottom: 20px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover{
  opacity: 0.6;
}
.bx-wrapper .bx-pager.bx-default-pager a.active {
  opacity: 1;
}
.bx-wrapper .bx-pager.bx-default-pager a {
  width: 68px;
  opacity: 0.2;
}
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: center;
  width: 100%;
}

@keyframes fadeIn{
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeOut {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

.inner-bg{
  padding-top: 25rem;
  .title,.short-text{
    opacity:0;
  }
}

#odoo, #testing, #integration, #enterprise, #prototyping, #custom{
  .inner-bg.active {
    .title{
      animation: fadeIn 0.75s forwards;
    }
    .short-text{
      animation: fadeIn 1s forwards;
    }
  }

  .inner-bg.active.leaving{
    .short-text,.title{
      animation: fadeOut 0.5s forwards;
    }
  }
}
#odoo{
  .odoo-link{
    color: $yellow-color; 
  }
  .computer, .get_in_touch{
    opacity:0;
  }
  .inner-bg{
    background: url(../images/sliders/odoo_logo.png) center center no-repeat;
    background-size: cover;
  }
  .inner-bg.active{
    .computer, .get_in_touch{
      animation: fadeIn 0.75s forwards;
    }
  }
  .inner-bg.active.leaving{
    .computer, .get_in_touch{
      animation: fadeOut 0.5s forwards;
    }
  }
  .short-text{
    margin-bottom: 5rem;
  }
}

@media (max-width: 991px) {
  #slider{
    #odoo{
      .inner-bg {
        padding-top: 20rem;
      }
      .short-text {
        font-size: 16px;
      }
    }
  }
}
#testing{
  background: url(../images/sliders/testing_bg.jpg) center center no-repeat;
  background-size: cover;
}
#enterprise{
  background: url(../images/sliders/enterprise_bg.jpg) center center no-repeat;
  background-size: cover;
}
#integration{
  background: url(../images/sliders/integration_bg.jpg) center center no-repeat;
  background-size: cover;
}
#custom{
  background: url(../images/sliders/prototyping_bg.jpg) center center no-repeat;
  background-size: cover;
}
#prototyping {
  background: url(../images/sliders/custom_bg.jpg) center center no-repeat;
  background-size: cover;
}
#odoo {
  background: url(../images/sliders/odoo_bg.jpg) center center no-repeat;
  background-size: cover;
}