
#exigen{
  .title{
    margin-top: 50px;
    margin-bottom: 37px;
  }
  .first-section{
    padding-bottom: 80px;
  }
  img{
    max-width: 100%;
    max-height: 100%;
  }
  .screen{
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.orange-shit{
  width: 200px;
  height: 180px;
  background-color: #ff895e;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: -50px;
  text-align: center;
  padding-top: 20px;
}
.handshake{
  margin-bottom: 20px;
}
.orange-bg{
  background-color: #ff895e;
}
@media(max-width: 400px){
  #exigen{
    #exigen-info{
      margin-top: 30px;
    }
    .brief-project-info{
      padding: 0px;
    }
    .brief-project-info.left div{
      text-align: right;
    }
  }

}