#clients{
  -webkit-transform: perspective(1px) skew(0deg, -10deg);
  transform: perspective(1px) skew(0deg, -10deg);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  padding-top: 140px;
  margin-bottom: 150px;

  #clients-title, .short-text{
    padding-right: 15px;
    padding-left: 15px;
  }

  .client-container{
    -webkit-transform: perspective(1px) skew(0deg, 10deg);
    transform: perspective(1px) skew(0deg, 10deg);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .client-slide{
    margin-top: 25px;
    width: 820px;
    height: 415px;
    background: white;

    .client-name, .client-position, .client-description{
      opacity: .5;
    }
    .client-name{
      left: 800px;
    }
    .client-position{
      left: 800px;
    }
    .client-description{
      left: 800px;
   
    }
    .client-slide-wrap{
      background-position-x: 200%;
    }
  }
  .client-slide.active{
    margin-top: 0px;
    opacity: 1;
    height: 465px;
    .client-name, .client-position, .client-description{
      opacity: 1;
    }

  }

  .client-slide.animate{
    .client-name{
      animation: clientSlideIn .6s;
      left: 0px;
    }
    .client-position{
      animation: clientSlideIn  .8s;
      left: 0px;
    }
    .client-description{
      animation: clientSlideIn 1s;
      left: 0px;
    }
    .client-slide-wrap{
      animation: quoteSlideIn 1s;
      background-position-x: 10%;
    }
  }

  .client-slide-wrap{
    text-align: center;
    background: url(../images/quote.svg);
    background-repeat: no-repeat;
    background-position-x: 10%;
    background-position-y: 41%;
    background-size: 132px 132px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 110px;
    padding-right: 70px;
    padding-left: 70px;
  }

  .client-slide.active .client-slide-wrap{
    padding-top: 140px;
    padding-right: 110px;
    padding-left: 110px;
  }

  .client-slider li, .client-slide.active, .client-slide{
    border-radius: 10px;
  }
  .client-slider .client-slide{
    box-shadow: 0.7px 0.7px 2px 0 rgba(180, 180, 180, 0.35), 2.1px 2.1px 5px 0 rgba(139, 139, 139, 0.35);
  }


  .bx-wrapper .bx-viewport{
    background: $red-color;
  }
  .bx-viewport{
    overflow: visible !important;
    border-radius: 10px;
  }
  .client-name{
    color: $red-color;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .client-position{
    margin-bottom: 30px;
  }
  .client-position, .client-description{
    font-size: 14px;
    color: #2e2e2e;
  }
  .bx-wrapper .bx-controls-direction a {

    width: 60px;
    height: 60px;
    svg{
      width: 30px;
      height: 30px;
      position: absolute;
      top: 15px;
    }
  }
  .bx-wrapper .bx-prev {
    left: -30px;
    svg{
      left: 12px;
    }
  }
  .bx-wrapper .bx-next {
    right: -30px;
    svg{
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      left: 17px;
    }
  }
  .bx-wrapper{
    .bx-prev, .bx-next {
      opacity: 0.9;
      transition: opacity .4s;
      background: red;
      border-radius: 50%;
      box-shadow: 3.5px 3.5px 10px 0 #940e0c;
    }
    .bx-prev:hover {
      opacity: 1;
    }
    .bx-next:hover {
      opacity: 1;
    }
    .bx-prev:active {
      margin-top: -15px;
      left: -29px;
    }
    .bx-next:active {
      margin-top: -15px;
      right: -31px;
    }
  }
}

.shadow, .shadow2{
  -webkit-box-shadow: 5px 5px 25px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 25px 2px rgba(0,0,0,0.75);
  box-shadow: 5px 5px 25px 2px rgba(0,0,0,0.75);
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
}

.shadow2{
  width: 64%;
  height: 148px;
  left: 2%;
  z-index: 0;
}
.shadow3{
  width: 289px;
  height: 2px;
  left: 39px;
  -webkit-box-shadow: 5px 5px 25px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 25px 2px rgba(0,0,0,0.75);
  box-shadow: 5px 5px 25px 2px rgba(0,0,0,0.75);
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  position: absolute;
  bottom: -20px;
}

.partner-wrap{
  height: 375px;
  position: relative;
  width: 255px;
  font-family: 'Montserrat-Hairline';
  border-right: 1px solid $light-color;
  border-bottom: 1px solid $light-color;
  border-radius: 8px;
  text-align: center;
  padding-top: 60px;
  color: $red-color;
  overflow: hidden;

}
.partner-col{
  margin-bottom: 65px;
  .opal{
    background: url(../images/logo/opal.png) center bottom no-repeat;
  }
  .ecolines{
    background: url(../images/logo/ecolines.png) center bottom no-repeat;
  }
  .rn{
    background: url(../images/logo/rn.png) center bottom no-repeat;
  }
  .rta{
    background: url(../images/logo/ldz.png) center bottom no-repeat;
  }

  .opal,  .ecolines,  .rn,  .rta{
    width: 255px;
    height: 110px;
  }
}
.partner{
  width: 100%;
  height: 100%;
}
.roof:before{
  content: '';
  width: 195px;
  height: 230px;
  position: absolute;
  top: 0px;
  left: -100px;
  background: white;
  transform: skewX(-44deg);
  -webkit-transform: skewX(-44deg);
  -moz-transform: skewX(-44deg);
  -o-transform: skewX(-44deg);
  -ms-transform: skewX(-44deg);

}
.roof:after{
  content: '';
  width: 189px;
  height: 230px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: url(../images/Line.png) center center no-repeat;
}

.years{
  width: 100%;

  display: inline-block;
}
.year{
  width: 100%;
  font-size: 115px;
  line-height: 1;
  margin-left: -400px;
  clear: both;
}
.year-txt{
  width: 100%;
  margin-bottom: 45px;
  text-align: left;
  padding-left: 52px;
  margin-left: -400px;
}
.partner .image{
  height: 75px;
  margin-left: -600px;
  display: block;
  width: 100%;
}
.partner .image.active, .year.active, .year-txt.active{
  margin-left: 0px;
}
.exigen{
  background: url(../images//logo/exigen.png) center center no-repeat;
}
.kleintech{
  background: url(../images/Kleintech.png) center center no-repeat;
}
.lattelecom {
  background: url(../images/logo/lattelecom.png) center center no-repeat;
}
.btg{
  background: url(../images/BTG.png) center center no-repeat;
}
.dzc{
  background: url(../images/dzc.png) center center no-repeat;
}
.cubesystem{
  background: url(../images/logo/cube-system.png) center center no-repeat;
}
.lvbank{
  background: url(../images/logo/latvijas-banka.png) center center no-repeat;
}
.partner-wrap{
  .cubesystem{
    margin-top:15px;
  }
}