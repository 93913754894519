button{
  border-image-width: 0;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;

}

.red-button{
  border-radius: 8px;
  background-color: #e21d1a;
  box-shadow: 0.6px 0.8px 9px 0 rgba(98, 1, 0, 0.15);
  text-align: center;
  text-decoration: none;
  padding: 6px 20px;
  color: #ffffff;
  outline: 0;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: 0.4px;
}
.red-button:hover{
  background-color: #f4c732;
  color: $darker-color;
}
.red-button, .brochure-text, .brochure-text .brochure-icon{
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all  .2s;
}

