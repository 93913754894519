.technology, .industries, .categories{
  text-align: center;
}
.title.industries-title{
  margin-bottom: 8rem;
}
.industries {
  height: 18rem;
  margin-bottom: 20px;
  img{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all  .5s;
  }
  .name{
    font-size: 22px;
    font-weight: 800;
    line-height: 1.36;
    letter-spacing: 0.6px;
    position: relative;
    width: 100%;
  }
  &:hover{
    img{
      opacity: 1;
    }
  }
}
.industries-wrap{
  position: relative;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: url(../images/industries/bg1.jpg) center center no-repeat;
  background-size: cover;
}
.industries:nth-of-type(2), .industries:nth-of-type(5){
  .industries-wrap{
    background: url(../images/industries/bg2.jpg) center center no-repeat;
    background-size: cover;
  }
}

@media (max-width: 1199px){
  .industries {
    height: 16rem;
    margin-bottom: 35px;
    .name {
      font-size: 18px;
    }
  }
}
@media (max-width: 991px){
  .title.industries-title{
    margin-bottom: 6rem;
  }
  .industries {
    height: 13rem;
    margin-bottom: 17px;
    .name {
      font-size: 16px;
    }
    .industries-wrap{
      background: url(../images/industries/bg2.jpg) center center no-repeat;
      background-size: cover;
    }
  }
}

@media (max-width: 480px){
  .industries{
    width: 100%;
    height: 10rem;
  }
}