@media (max-width: 767px){
  .navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    color: #adadad;
  }

  .navbar-collapse.collapse{
    overflow: visible;
  }

  .navbar-nav .open .dropdown-menu.languages {
    position: absolute;
    background-color: #ffffff;
    margin-top: 12px;
    left: 10px;
    &:hover {
      background-color: #f4c732;
      border-color: #f4c732;
    }
  }
  .locale-dropdown {
    padding-left: 10px;
  }
}

@media (min-width: 768px) {
  .navbar-default{
    background-color: transparent;
    border-color: transparent;
    z-index: 1;
  }
  .navbar {
    border-radius: 0px;
    margin-bottom: 0px;
  }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form{
    border: none;
  }

  .navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: white;
    font-size: 14px;
  }
  .navbar-nav > li > a{
    padding-top: 53px;
  }
  .sticky .navbar-nav > li > a {
    padding-top: 23px;
  }
  .navbar-nav > li > a:after{
    content: "";
    display: block;
    height: 2px;
    background-color: transparent;
    width: 0;
    position: absolute;
    margin: -5px auto 0;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  .navbar-nav > li > a:hover:after{
    background-color: white;
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 80%;
    width: calc(100% - 30px);
    margin: 7px auto 0;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  .navbar-brand{
    padding-top: 41px;
  }
  .sticky .navbar-brand{
    padding-top: 7px;
  }
  .locale-dropdown {
    padding-top: 47px;
    padding-left: 15px;
  }
  .sticky{
    .locale-dropdown {
      padding-top: 17px;
    }
  }
}
.navbar {
  margin-bottom: 0px;
  img.logo{
    width: 127px;
    height: 40px;
  }
}

.locale-dropdown{
  .dropdown-menu:before{
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #ffffff transparent;

    position: absolute;
    top: -5px;
    left: 19px;
  }
  .dropdown-menu{
    min-width: 50px;
    //box-shadow: none;
    border: none;
    margin: 12px 0 0;
    &:hover:before{
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #f4c732 transparent;

      position: absolute;
      top: -5px;
      left: 19px;
    }
  }

  .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: 0;
  }

}

#locale-switch, .locale-select{
  text-transform: uppercase;
  min-width: 50px;
  text-align: center;
}

#locale-switch{
  border-radius: 15px;
  font-size: 16px;
  line-height: 1;
  background-color: #ffffff;
  border-color: #ffffff;
  &:hover{
    background-color: #f4c732;
    border-color: #f4c732;
  }
}

.locale-select{
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #0a151e;
  line-height: 1;
  padding: 4px 9px;
}

.dropdown-menu.languages{
  li{
    cursor: pointer;
  }
  &:hover{
    background-color: #f4c732;
    border-color: #f4c732;
  }
}