@media (max-width: 1500px){
  .coffee-holder {
    width: 200px;
    height: 150px;
    .cofe {
      max-width: 200px;
      max-height: 150px;
    }
    img{
      max-width: 100%;
    }
    .pencil {
      bottom: -61px;
    }
  }
  .shadow2{
    width: 68%;
    height: 119px;
  }
  .shadow3 {
    width: 170px;
    bottom: -16px;
  }
  .coffee-holder {
    right: 3%;
  }
}

@media (max-width: 1370px) {
  #slider .slider-container {
    height: 750px;
    .title{
      margin-bottom: 25px;
    }
  }
  #slider, #slider .bx-wrapper, #slider .bx-viewport, #slider .inner-bg{
    height: 670px;
  }

  .holder{
    max-width: 230px;
  }

  .bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
    bottom: 90px;
  }
}

@media (min-width: 768px){
  #slider{
    .bx-pager{
      width: 750px;
    }
  }
}
@media (min-width: 992px) {
  #slider{
    .bx-pager{
      width: 970px;
    }
  }
  .partner-wrap, .partner-col .opal, .partner-col .ecolines, .partner-col .rn, .partner-col .rta {
    width: 100%;
  }

  #ept{
    .ept-project {
      width: 950px;
    }
  }
}
@media (min-width: 1200px){
  .navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: white;
    font-size: 16px;
  }
  .title{
    font-size: 48px;
  }

  #yellow-bg2 {
    img {
      margin-bottom: -90px;
    }
  }
  #slider{
    .bx-pager{
      width: 1170px;
    }
  }
}
@media (min-width: 1400px){
  #slider .bx-pager{
    width: 1240px;
  }
}

@media (max-width: 1200px) {
  .title {
    font-size: 40px;
  }
  .holder{
    max-width: 180px;
    top: 125px;
  }
  .center-sm{
    width: initial;
    margin: 0 auto;
    float: none;
    display: table;
  }
}
@media (max-width: 1199px) {
  .service-title {
    font-size: 19px;
  }
  .technology-wrap{
    .name {
      font-size: 14px;
    }
    .technology .icon {
      margin-bottom: 15px;
    }
  }
}
@media (max-width: 1370px) {
  #slider .title{
    font-size: 40px;
  }
}

