#services{
  padding-top: 86px;
  padding-bottom: 120px;
}

.services-list ul{
  -webkit-padding-start: 16px;
  font-size: 13px;
  line-height: 2.14;
  letter-spacing: 0.4px;
}
.service-type{
  margin-bottom: 20px;
}
.service-title{
   font-size: 21px;
 }

@media (min-width: 1400px){
  .service-title{
    width: 100%;
  }
}