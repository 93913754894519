@keyframes spawn {
0%   {   opacity: 0;}
100%  {  opacity: 1;}
}
@keyframes dissapear {
0%  {  opacity: 1;}
100%  {   opacity: 0;}
}
.item-wrap:not(ng-hide){
  animation: spawn 1s;
}
.item-wrap.ng-hide{
  animation: dissapear 1s;
}

.case-studies-wrap.main-page{
  .bx-viewport{
    height: 270px!important;
    &:hover{
      .left-gradient{
        left: -500px;
      }
      .right-gradient{
        right: -500px;
      }
    }
  }
}

.left-gradient, .right-gradient{
  height: 100%;
  z-index: 1;
  width: 180px;
  position: absolute;
  top: 0px;
  bottom: 0px;
}
.left-gradient{
  left: 0px;
  background: linear-gradient( to left, rgba( 256, 256, 256, 0), rgba( 256, 256, 256, 1));
}
.right-gradient{
  right: 0px;
  background: linear-gradient( to right, rgba( 256, 256, 256, 0), rgba( 256, 256, 256, 1));
}
.case-studies-slider{
  height: 270px;
  opacity: 0;
  padding-top: 5px;
  padding-bottom: 10px;
  .item-wrap{
    height: 100%;
    width: 255px;
  }
}
.case-studies-wrap{
  .top{
    height: 60%;
  }
  .bottom{
    height: 40%;
  }
  .icon, .hover_icon{
    width: 28px;
    height: 28px;
  }
  .hover_icon{
    display: none;
  }
  .case-studie{
    padding: 28px 30px 40px;
    color: #393939;

    background:rgba( 256, 256, 256, 1);
    a{

      color: #393939;
    }
    .item-title{
      line-height: 1.5;
      letter-spacing: .5px;
      font-size: 18px;
      padding-top: 10px;
      font-family: 'Montserrat Bold', sans-serif;
    }
  }
  .item-wrap{
    height: 255px;
    border-radius: 10px;
    box-shadow: 2.1px 2.1px 15px 0 rgba(166, 166, 166, 0.35);

    background-size: cover !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;

    .case-studie{
      border-radius: 8px;
      height: 100%;
    }
    &:hover{
      .case-studie{
        background:rgba( 226, 29, 26, 0.85);
        color: white;
        a{
          color: white;
          &:hover{
            color: $yellow-color
          }
        }
      }

      .icon, .item-logo{
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
      }

    }
  }
  .partners-since{
    font-size: 12px;
    display: none;
  }
  .item-logo{
    width: 145px;
    height: 39px;
    margin-top: 20px;
  }

  &.extended{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;

    .item-wrap{
      width: 280px;
      margin-bottom: 30px;
      float: none;
      margin-right: 10px;
      margin-left: 10px;
      display: inline-block;
    }
  }
}

// IE fix
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .item-wrap:hover{
    .icon{
      display: none;
    }
    .hover_icon{
      display: block;
    }
  }
}
.left-gradient, .right-gradient, .case-studie, .case-studie a{
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -o-transition: all .3s;
  transition: all  .3s;
}
.brochure-icon{
  width: 60px;
  height: 60px;
  background-color: #e21d1a;
  border-radius:50%;
  float: right;
  box-shadow: 3.5px 3.5px 10px 0 #9a0b08;
  .pdf-icon{
    //background: url(../images/pdf.svg) center center no-repeat;
    width: 25px;
    height: 25px;
    margin: 17.5px;
  }
}

.brochure-btn-wrap{
  margin-bottom: 70px;
}
.brochure-text {
  span{
    padding-left: 30px;
    padding-right:25px;
  }
  width: 262px;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #212121;
  line-height: 60px;
  margin:0 auto;
  border-radius: 30px;
  border: solid 1px #d9d9d9;
}
.brochure-text:hover{
  border: solid 1px #f4c732;
  color: #e31d1a;
  .brochure-icon{
    background-color: #f4c732;
    box-shadow: 3.5px 3.5px 10px 0 #f4c732;
  }
}
.all-case-stadies{
  height: 100%;


}
@media (max-width: 1399px) {
  .case-studies-wrap.extended{
    .item-wrap {
      width: 264px;
    }
  }
}
@media (max-width: 1199px) {
  .case-studies-wrap{
    &.main-page{
      width: 920px;
      margin: 0 auto;
      float: none;
    }
    &.extended{
      .item-wrap {
        width: 292px;
      }
    }
  }
}
@media (max-width: 991px) {
  .case-studies-wrap{
    &.main-page{
      width: 622px;
    }
    &.extended{
      .item-wrap {
        width: 338px;
      }
    }
  }
  .left-gradient, .right-gradient{
    display: none;
  }
}
@media (max-width: 767px) {
  .case-studies-wrap.extended{
    .item-wrap {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 680px) {
  .case-studies-wrap{
    &.main-page{
      width: 322px;
    }
  }
}
//New case studies
.LDZBGimg, .LBankBGimg, .CKBGimg, .AmberBGimg, .red-gradient{
  height: 100%;
}
.red-gradient{
  background: linear-gradient(0deg,#e21d1a,rgba(226,29,26,0)) bottom no-repeat;
  background-size: 100% 270px;
}
#newCaseStudies
{
  background-color: $darker-color;
  min-height: calc( 100% - 255px);
}
.LDZBGimg{
    background: url(../images/case-studies/LDZ/bg.jpg) center center no-repeat;
    background-size: cover;
  padding-top: 190px;
}
.trafficLight{
    background:  url(../images/case-studies/LDZ/image.png) center center no-repeat;
    width: 317px;
    height: 559px;
    position: relative;
    margin: 0 auto;
}
.LDZLogo{
  height: 118px;
  width: 118px;
  background:  url(../images/case-studies/LDZ/logo-white.png)  no-repeat;
}

.LBankBGimg{
  background: url(../images/case-studies/Latvijas_Banka/bg.jpg) center center no-repeat;
  background-size: cover;
  padding-top: 190px;
  .hidden-sm.hidden-xs{
    height: 550px;
  }
}
.LBankImage{
  background:  url(../images/case-studies/Latvijas_Banka/image.png) top right no-repeat;
  width: 601px;
  height: 791px;
  background-size: contain;
}
.LBankLogo{
  height: 118px;
  width: 252px;
  background:  url(../images/case-studies/Latvijas_Banka/logo-white.png) no-repeat;
}

.CKBGimg{
  background: url(../images/case-studies/Cesu_klinika/bg.jpg) center center no-repeat;
  background-size: cover;
  padding-top: 190px;
}
.CKImage{
  background:  url(../images/case-studies/Cesu_klinika/image.png) top left no-repeat;
  width: 354px;
  height: 490px;
  position: relative;
  margin: 0 auto;
}
.CKLogo{
  width: 120px;
  height: 173px;
  background:  url(../images/case-studies/Cesu_klinika/logo-white.png) no-repeat;
}

.AmberBGimg{
  background: url(../images/case-studies/Amber-housing/bg.jpg) center center no-repeat;
  background-size: cover;
  padding-top: 190px;
}
.AmberImage{
  background:  url(../images/case-studies/Amber-housing/image.png) top left no-repeat;
  width: 803px;
  height: 498px;
  position: relative;
  margin: 0 auto;
}
.AmberLogo{
  width: 135px;
  height: 82px;
  background:  url(../images/case-studies/Amber-housing/logo-white.png) no-repeat;
}

.LDZLogo, .LBankLogo, .AmberLogo, .CKLogo{
  margin-bottom: 250px;
}

.caseStudyText{
  padding-top:49px;
  line-height: 1.67;
  padding-bottom: 100px;
  .title{
    font-size: 48px;
    line-height: 1.25;
    letter-spacing: 1.2px;
  }
  .boldText{
    font-size: 24px;
    letter-spacing: 0.6px;
  }
  .paragraphText{
    font-size: 18px;
    letter-spacing: 0.5px;
    text-align: justify;
  }
}
@media (max-width: 1360px) {
  .LBankImage {
    width: 400px;
    height: 550px;
  }
}

@media (max-width: 767px){

  .LDZBGimg, .LBankBGimg, .CKBGimg, .AmberBGimg{
    padding-top: 0px;
  }
  .LDZLogo, .LBankLogo, .AmberLogo, .CKLogo{
    margin: 0 auto;
    margin-bottom: 100px;
  }

  .caseStudyText{
    text-align: center;
    padding-bottom: 50px;
    .title, .boldText, .paragraphText{
      letter-spacing: normal;
    }
    .title {
      font-size: 32px;
    }
    .boldText{
      font-size: 20px;
    }
    .paragraphText{
      font-size: 16px;
    }
  }
}
@media (min-width: 768px){
  #newCaseStudies{
    margin-top: -100px;
  }
}