
$darker-color: #0a151e;
$dark-color: #212121;
$dark-blue-color: #00233f;
$gray-color: #eef1f7;
$yellow-color: #f4c631;
$yellow-color2: #ffd200;
$light-color: #ebebeb;
$red-color: #e21d1a;
$red-color2: #ff3719;
$brown-color: #602317;