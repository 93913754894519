@mixin animate( $property, $time, $animation ) {
  -webkit-transition: $property $time $animation;
  -moz-transition: $property $time $animation;
  -ms-transition: $property $time $animation;
  -o-transition: $property $time $animation;
  transition: $property  $time $animation;
}
@mixin border-radius( $radius ) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
@mixin move( $x, $y ){
  transform: translate($x, $y);
  -webkit-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
}
@mixin background-size( $val ){
  -webkit-background-size: $val;
  -moz-background-size: $val;
  -o-background-size: $val;
  background-size: $val;
}