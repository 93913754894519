#els, #ein, #ept, #eow, #kps, #cinamon, #rn, #ingredient-matcher, #svs, #etex, #softkinetic,
#ads, #opal-online, #lb, #cube, #servers, #darlite, #fom, #bonshine, #et, #amber, #tapbox, #bi{
  .top-info{
    img{
      border-radius: 3px;
    }
  }
  img{
    max-width: 100%;
  }
  p{
    line-height: 1.88;
    letter-spacing: 0.4px;
  }
  .bold-title{
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    font-family: 'Montserrat-ExtraBold', sans-serif;
  }
  .top-info{
    padding-top: 4rem;
    h2{
      line-height: 1.25;
    }
    h4{
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      line-height: 1.5;
    }
    p{
      font-size: 14px;
    }
  }
  .black{
    color: #0a151e;
  }
  .dark-red-bg{
    background-color: #ad342b;
  }
  .cherry-bg{
    background-color: #8c0000;
  }
  .cherry{
    color: #8c0000;
  }

  .tapbox-blue-back{
    background-color: #00bbf2;
  }
  .light-blue{
    color: #3ebae3;
  }
  .light-blue-back{
    background-color: #3ebae3;
  }
  .incubator-blue-bg{
    background-color: #317bb5;
  }
  .incubator-blue-color{
    color: #317bb5;
  }
  .dark-blue{
    color: #3b3a8f;
  }
  .cube-bg{
    background-color: #3b3a8f;
  }
  .yellow-border, .red-border, .red-border2, .green-border, .green-border2, .blue-border, .cherry-border, .light-blue-border,
  .cube-border, .violet-border, .brown-border, .bonshine-border, .eurotrans-border, .green-border3, .tapbox-border{
    position: relative;
    padding-top: 3.5rem;
    padding-bottom: 3rem;
    padding-left: 7.5rem;
    padding-right: 7.5rem;
    border-radius: 4px;

    margin: 0 auto;
    margin-bottom: 9.5rem;
    margin-top: 12rem;
    img{
      position: absolute;
      left: -32%;
      top: -28%;
    }

    p{
      font-size: 18px;
    }
    h4{
      line-height: 1.4;
    }
  }
  .tapbox-border{
    padding: 2rem 5rem;
    border: solid 2px #00bbf2;
    .blue{
      color: #00bbf2;
    }
    img{
      position: absolute;
      left: -16%;
      top: 40%;
    }
  }
  .eurotrans-border{
    border: solid 2px #ad342b;
    color: #ad342b;

  }
  .cherry-border{
    border: solid 2px #8c0000;
    padding-top: 2rem;
    padding-bottom: 3rem;
    padding-left: 10.15rem;
    padding-right: 10.15rem;
    img{
      left: -44%;
      top: -7%;
    }
  }
  .cube-border{
    border: solid 2px #3b3a8f;
  }
  .yellow-border{
    border: solid 2px #ffd200;
  }
  .blue-border{
    border: solid 2px #2f556e;
    .blue{
      color: #2f556e;
    }
  }
  .brown-border{
    border: solid 2px $brown-color;
    padding: 2rem 4.5rem;
    .brown{
      color: $brown-color;
    }
  }
  .light-blue-border{
    border: solid 2px #3ebae3;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .green-border{
    border: solid 2px #37a13d;
    img{
      left: -20%;
      top: -8%;
    }
  }
  .green-border2{
    border: solid 2px #40a66b;
    padding-top: 2rem;
    padding-bottom: 3rem;
    padding-left: 5.5rem;
    padding-right: 5.5rem;
    img{
      left: -38%;
      top: -7%;
    }
  }
  .green-border3{
    border: solid 2px #2ecc71;
    padding: 2rem 3rem;
    .green{
      color: #2ecc71;
    }
  }
  .bonshine-border{
    border: solid 2px #4ab91f;
    padding: 2rem 10rem;
    .bonshine-green{
      color: #4ab91f;
    }
    img{
      left: -22%;
    }
  }
  .red-border{
    border: solid 2px #d80b42;
  }
  .red-border2{
    border: solid 2px $red-color2;
  }
  .offers, .menus{
    margin-bottom: 6rem;
    img{
      max-width: 100%;
    }
    h2{
      font-size: 32px;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: 2rem;
      letter-spacing: 0.8px;
    }
  }
  .violet-border{
    border: solid 2px #d22e7c;
    padding-top: 2rem;
    padding-bottom: 3rem;
    padding-left: 11.6rem;
    padding-right: 11.6rem;
    .violet{
      color: #d22e7c;
    }
    img{
      max-width: 85rem;
      left: -20%;
    }
  }
  .offers{

    .right-side{
      padding-left: 4.4rem;
    }
    .left-side{
      padding-right: 4.4rem;
      padding-left: 0;
    }
  }
  .menus{

    .left-side{
      max-width: 598px;
      padding: 0;
    }
    .right-side{
      float: right;
    }
    img {
      position: relative;
      z-index: 1;
    }
  }
  .gray-bg{
    margin-bottom: 8rem;
    .block{
      text-align: center;
      padding-top: 9.5rem;
      padding-bottom: 9.5rem;
    }
    h2{
      margin-bottom: 3.5rem;
      line-height: 1.5;
    }
  }
  .rules{
    margin-bottom: 8.5rem;
  }
}
.darlite{
  margin-top: -10vw;
}
#etex{
  .bg{
    background: url(../images/etex/bg.jpg) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
#darlite{
  .bg{
    background: url(../images/darlite/bg.jpg) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .gray-bg{
    margin-bottom: 0;
  }
}
#fom{
  .bg{
    background: url(../images/fom/museum_background.jpg) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .offers{
    img{
      margin-bottom: 2.9rem;
    }
    h2{
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      margin-bottom:3rem;
    }
    .hidden-sm{
      text-align: right;
    }
  }

}
#et, #softkinetic, #bi{
  .gray-bg{
    margin-bottom: 0;
  }
}

#svs, #lb, #servers{
  .top-info{
    background: #2f556e;
  }
  .offers{
    .left-side{
      padding-left: 1.5rem;
    }
  }
}
#lb{
  .container .offers p{
    margin-bottom: 9rem;
  }
  .blue-border{
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    padding-left: 6.5rem;
    padding-right: 6.5rem;
    img{
      top: -18%;
    }
  }
  .gray-bg{
    margin-bottom: 0;
  }
}
#ingredient-matcher{
  .top-info{
    padding-bottom: 30px;
  }
}
.rules{
  h2{
    margin-bottom: 25px;
    margin-top: 25px;
  }
}
.rn-green-bg{
  background: linear-gradient(to right, #37a13d 10%, #a5d422 35%,#a5d422 35%, #37a13d 100%);
}
.amber-gradient{
  background: linear-gradient(to right, #354052 60%, #2ac771);
}
.bonshine-bg{
  background-image: linear-gradient(to right, #ffffff 20%, #83d548 72%, #399113);
}
#eow{
  .bg{
    background: #354052;
  }
  .top-info{
    background: url(../images/eow/bg.jpg) center right no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .bus{
    position: relative;
  }
  .offers .left-side{
    padding-right: 15px;
  }
  .right-side{
    h2{
      margin-top: 0;
    }
  }
}
#ein{
  .yellow-border {
    padding: 3.5rem 5.5rem 3rem 5.5rem;
  }
}
#ept{
  .top-info{
      background: url(../images/ept/yellowbg.png) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .yellow-border {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}
#ept, #lb, #cube, #servers, #darlite{
  .offers{
    max-width: 86rem;
    margin: 0 auto;
    float: none;
    p{
      margin-bottom: 4.5rem;
    }
  }
}
#kps{
  .top-info{
    img{
      -webkit-box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.2);
      box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.2);
    }
  }
  .dark-blue-bg{
    padding: 1rem 3rem;
  }
  .second-part{
    color: #487089;
    padding-left: 1.5rem;
  }
}
#ads{
  .top-info{
    h2{
      margin-top: 0.5rem;
    }
  }
  .menus{
    margin-bottom: 12.5rem;
  }
  .yellow-border{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    padding-left: 6rem;
    padding-right: 4rem;
  }
  .person{
    width: 319px;
    height: 114px;
    border-radius: 10px;
    box-shadow: -5.4px 2.6px 57px 0 rgba(0, 0, 0, 0.47);
    padding-top: 2.3rem;
    padding-bottom: 2.3rem;
    padding-left: 1.9rem;
    padding-right: 2.5rem;
    font-size: 16px;
    line-height: 1.45;
    letter-spacing: 0.4px;
    color: #0a151e;
    position: absolute;
    z-index: 2;
    bottom: -58px;
    right: 63px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    img{
      float: left;
      margin-right: 1.9rem;
      max-height: 100%;
    }
  }
  .rules{
    .texts{
      padding-left: 3.5rem;
      padding-top: 2.5rem;
      padding-right: 3.5rem;
      padding-bottom: 2.5rem;
      display: inline-block;
      position: relative;
      font-weight: 500;
      line-height: 1.88;
      letter-spacing: 0.4px;
      color: #0a151e;
    }
  }
}
#cube{
  .offers{
    h2{
      max-width: 530px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .cube-border{
    img{
      left: -28%;
      top: -19%;
    }
  }
}
.info-inside{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  .sphere{
    width: 392px;
    height: 392px;
    background-color: #eef1f7;
    margin: 0 auto;
    padding-left: 9rem;
    padding-right: 9rem;

    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
    letter-spacing: 0.5px;
    color: #0a151e;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
  }
}
.image-row{
  margin-bottom: 4rem;
  .rounded{
    border-radius: 10px;
  }
  &:last-of-type{
    margin-bottom: 10rem;
  }
}
.surface{
  z-index: 1;
  position: relative;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.5);
}
.small-img{
  min-width: 492px;
}
.big-img{
  min-width: 746px;
}
.little-left{
  right: 58px;
  position: relative;
}

.infa-right, .infa-left{
  display: inline-block;
  padding: 1.8rem 7rem;
  position: relative;
  background-color: #eef1f7;
}

.heart{
  position: absolute;
  top: 30px;
  left: -90px;
  z-index: 3;
}
.monitor-text-wrap{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.monitor-text{
  margin: 0 auto;
}
#els, #eow, #ein, #ept, #svs, #opal-online, #bonshine, #et, #rn, #fom, #amber{
  .monitor-text{
    &.gray-bg{
      padding: 1rem 2rem;
    }
    &.brown{
      color: #602317;
    }
  }
}
#cinamon{
  .monitor-text.red-bg2{
    padding: 1rem 3rem;
  }
}

#tapbox{
  .offers{
    h2{
      max-width: 665px;
    }
    p{
      max-width: 815px;
    }
    h2, p{
      margin: 0 auto 30px;
    }
    img{
      margin-top: 50px;
    }
  }
  .gray-bg {
    margin-bottom: 0;
  }
}
#bi{
  .blue-border{
    border: solid 2px #317bb5;
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    img{
      position: absolute;
      left: -16%;
      top: 40%;
    }
  }
  .offers{
    p{
      margin-bottom: 5rem;
    }
  }
}


/* Amber */
.benefit-wrapper {
  width: 100%;
  height: 170px;
  position: relative;
  display: inline-block;
  border: 3px solid #f9bf3b;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 45px;
  .benefit-icon {
    position: absolute;
    display: inline-block;
    bottom: -80px;
    left: -70px;
    height: 200px;
    width: 200px;
    opacity: .8;
    transition: all 1s linear;

  }
  .watch {
    background-image: url(../images/case-studies/Amber-housing/watch.svg);
  }
  .pig {
    background-image: url(../images/case-studies/Amber-housing/pig.svg);
  }
  .star {
    background-image: url(../images/case-studies/Amber-housing/star.svg);
  }
  .ipad {
    background-image: url(../images/case-studies/Amber-housing/ipad.svg);
  }
  p {
    font-family: Roboto,sans-serif;
    font-weight: 700;
    color: #354052;
    font-size: 26px;
    padding-left: 140px;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    padding-right: 40px;
  }
  &:hover{
    .triangle-star, p {
      display: none;
    }
    .hover-text-wrapper {
      display: block;
    }
  }
  &:hover .benefit-icon {
    bottom: -50px;
    left: -50px;
  }
  &.amber-green-border{
    border-color: #2ecc71;
  }
}
.triangle-star {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 80px 80px 0;
  border-color: transparent #fff4da transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  &:before{
    content: "";
    position: relative;
    display: inline-block;
    width: 120px;
    top: 26px;
    right: 21px;
    height: 3px;
    background-color: #f9bf3b;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &:after{
    content: "";
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    top: -18px;
    right: -35px;
    background-image: url(../images/case-studies/Amber-housing/star_circle.svg);
    background-repeat: no-repeat;
  }
}
.hover-text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(249,191,59,.9);
  z-index: 2;
  display: none;
  span {
    position: relative;
    display: inline-block;
    font-family: Open Sans,sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    padding: 0 40px;
    line-height: 26px;
    text-align: center;
  }
}
.amber-green-border{
  .hover-text-wrapper {
    background-color: rgba(46,204,113,.9);
  }
}









@media (max-width: 1399px) {
  .little-left {
    right: 98px;
  }
  .small-img{
    min-width: 463px;
  }
}
@media (max-width: 1199px) {
  #els, #ein, #ept, #eow, #kps, #cinamon, #rn, #ingredient-matcher, #svs, #etex, #softkinetic, #ads, #opal-online, #lb, #cube, #servers, #darlite, #fom, #bonshine, #et, #amber, #tapbox, #bi{
    .top-info {
      padding-bottom: 5rem;
    }
    .bold-title {
      font-size: 28px;
    }
    .gray-bg{
      h2 {
        margin-bottom: 1.5rem;
      }
      .block {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      }
      p{
        font-size: 14px;
      }
    }
  }
  #eow{
    .top-info{
      .arrow{
        display: none;
      }
    }
    .offers {
      .right-side {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
  .info-inside .sphere {
    width: 330px;
    height: 330px;
    padding-left: 8rem;
    padding-right: 8rem;
    font-size: 14px;
  }
  #ads{
    .rules{
      margin-bottom: 0;
    }
  }
  .big-img {
    min-width: 629px;
  }
  .small-img {
    min-width: 379px;
  }
  #fom .offers h2{
    font-size: 28px;
  }
}
@media (max-width: 991px) {
  #els, #ein, #ept, #eow, #kps, #cinamon, #rn, #ingredient-matcher, #svs, #etex, #softkinetic, #ads, #opal-online, #lb, #cube, #servers, #darlite, #fom, #bonshine, #et, #amber, #tapbox, #bi{
    .offers{
      .left-side{
        padding-right: 15px;
      }
      .right-side{
        padding-left: 15px;
      }
      h2{
        font-size: 20px;
      }
    }
    .yellow-border, .red-border, .red-border2, .green-border, .green-border2, .green-border3, .blue-border,
    .cherry-border, .light-blue-border, .cube-border, .violet-border, .brown-border, .bonshine-border, .eurotrans-border, .tapbox-border{
      margin-bottom: 6rem;
      margin-top: 6rem;
      img {
        display: none;
      }
    }
  }
  #ein{
    .right-side{
      h2{
        font-size: 24px;
      }
    }
  }
  #eow .top-info {
    background-size: cover;
  }
  .info-inside .sphere {
    width: 250px;
    height: 250px;
    padding-left: 6rem;
    padding-right: 6rem;
    font-size: 13px;
  }
  #kps{
    .second-part{
      display: block;
      padding-left: 0;
    }
  }
  #ads{
    .person{
      width: 276px;
      height: 88px;
      font-size: 14px;
      padding: 1.5rem;
    }
    .rules{
      .texts{
        padding: 0.8rem 3.5rem;
      }
    }
    .buba{
      margin-top: -42px;
    }
  }
  .little-left {
    right: unset;
  }
  .big-img, .small-img {
    width: 100%;
    min-width: 100%;
  }
  .rounded{
    margin-bottom: 2rem;
  }
  .heart {
    top: 0;
    left: 0;
  }
  #fom{
    .offers{
      .left, .hidden-lg{
        text-align: center;
      }
    }
  }
}
@media (max-width: 767px) {
  #els, #ein, #ept, #eow, #kps, #cinamon, #rn, #ingredient-matcher, #svs, #etex, #softkinetic, #ads, #opal-online, #lb, #cube, #servers, #darlite, #fom, #bonshine, #et, #amber, #tapbox, #bi{
    .top-info{
      text-align: center;
      img{
        margin-top: 1rem;
      }
    }
    .block{
      border-bottom: solid 2px #ffffff;
      &:last-of-type{
        border-bottom: none;
      }
    }
    .yellow-border, .red-border, .red-border2, .green-border, .green-border2, .blue-border, .cherry-border, .light-blue-border, .cube-border, .bonshine-border, .green-border3, .tapbox-border{
      text-align: center;
      .bold-title {
        font-size: 22px;
      }
    }

  }
  #ein, #kps, #els, #eow, #cinamon, #rn, #ingredient-matcher, #svs, #ads, #opal-online, #amber {
    .menus, .offers{
      .left-side, .right-side{
        max-width: unset;
        text-align: center;
        h2{
          margin-top: 30px;
        }
      }
    }
  }

  #darlite{
    .violet-border{
      padding: 2rem;
    }
  }

  .info-inside .sphere{
    width: 50vw;
    height: 50vw;
    padding-left: 12vw;
    padding-right: 12vw;
    font-size: 2.2vw;
  }
  .rn-green-bg{
    background: linear-gradient(to right, #37a13d 10%, #a5d422 50%,#a5d422 50%, #37a13d 100%);
  }
  .monitor-text{
    font-size: 12px;
  }
  #ads{
    .right-side{
      margin-top: 6rem;
    }
    .menus{
      margin-bottom: 6rem;
    }
  }
  .heart{
    width: 15%;
  }
  #servers, #bonshine{
    .blue-border, .bonshine-border{
      padding: 2rem;
    }
  }
  #bonshine, #et{
    .offers{
      text-align: center;
      img{
        max-width: 80%;
        margin: 0 auto;
        display: block;
      }
    }
  }
}
@media (max-width: 600px) {
  #els .yellow-border {
    position: relative;
    padding: 2rem 4rem;
  }
 #eow .yellow-border{
    padding: 3.5rem 4.5rem 3rem;
  }
  #ein, #etex{
    .yellow-border {
      padding: 2rem;
    }
  }
  #rn{
    .green-border{
      padding: 3.5rem 6rem 3rem;
    }
  }
  #softkinetic{
    .cherry-border {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  #opal-online{
    .light-blue-border{
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
  #amber{
    .benefit-wrapper p {
      font-size: 20px;
    }
  }
}
@media (max-width: 480px) {
  #ads .person{
    position: relative;
    bottom: 28px;
    margin: 0 auto;
    right: unset;
  }
  .benefit-wrapper {
    .benefit-icon {
      height: 160px;
      width: 160px;
    }
    p{
      padding-left: 100px;
    }
  }
  .hover-text-wrapper span {
    font-size: 12px;
    padding: 0 10px;
  }
}
@media (max-width: 400px) {
  #amber{
    .benefit-wrapper {
      height: 200px;
      p {
        font-size: 18px;
      }
    }
  }
  #tapbox{
    .tapbox-border{
      h4{
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 360px) {
  #els, #ein, #ept, #eow, #kps, #cinamon, #rn, #ingredient-matcher, #svs, #etex, #softkinetic, #ads, #opal-online, #lb, #cube, #servers, #fom{
    .yellow-border, .red-border, .red-border2, .green-border, .green-border2, .blue-border, .cherry-border, .light-blue-border, .cube-border, .brown-border{
      padding: 2rem;
    }
  }
  #amber{
    .benefit-wrapper p {
      font-size: 16px;
    }
  }
}
@media(max-width: 679px){
  #fom{
    .offers{
      .top, .bottom{
        width: 100%;
      }
    }
  }
}
@media(min-width: 680px){
  #fom{
    .offers{
      .left, .right{
        .top, .bottom{
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
@media(min-width: 768px){
  #els, #ein, #ept, #eow, #kps, #cinamon, #ingredient-matcher, #svs, #etex, #softkinetic, #ads, #opal-online, #lb, #cube, #servers, #darlite, #fom, #bonshine, #et, #rn, #amber, #tapbox, #bi{
    .top-info {
      padding-top: 7.5rem;
    }
    .gray-bg {
      .container {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
      }
      .block{
        border-left: solid 2px #ffffff;
        &:last-of-type{
          border-right: solid 2px #ffffff;
        }
      }
    }

  }
  #ingredient-matcher{
    .rules{
      .container{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
      }
    }
  }
  #svs {
    .right-side{
      float: right;
    }
  }
  #opal-online{
    .menus{
      img{
        margin-top: 4rem;
      }
    }
  }
  #bonshine, #et{
    .offers{
      .container{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media(min-width: 768px) and ( max-width: 1199px ){
  #eow {
    .offers {
      .container {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
      }
    }
  }
  #ingredient-matcher, #softkinetic, #opal-online, #cube, #servers, #darlite, #fom, #bonshine, #et, #tapbox, #bi{
    .top-info, .menus {
      .container {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
      }
    }
  }
}
@media(min-width: 768px) and ( max-width: 991px ){
  #els, #ein, #ept, #eow, #kps, #cinamon, #rn, #etex, #ads, #amber{
    .top-info, .menus{
      .container{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
      }
    }
  }
  #ein{
    .right-side{
      padding-left: 6%;
    }
  }

}
@media(min-width: 992px) and ( max-width: 1199px ){
  #fom{
    .offers .top img{
      margin-bottom: 11rem;
    }
  }
}
@media(min-width: 922px){
  #els, #ein, #ept, #eow, #kps, #cinamon, #rn, #ingredient-matcher, #svs, #etex, #softkinetic, #ads, #opal-online, #lb, #cube, #servers, #darlite, #fom, #bonshine, #et, #tapbox, #bi{
    .yellow-border, .red-border, .red-border2, .green-border, .green-border2, .blue-border, .cherry-border, .light-blue-border,
    .cube-border, .violet-border, .brown-border, .eurotrans-border, .tapbox-border{
      width: 635px;
    }
    .offers{
      .right-side{
        max-width: 47rem;
      }
    }
  }
  #amber{
    .green-border3{
      width: 735px;
    }
  }
  #servers{
    .blue-border{
      width: 735px;
      padding-top: 2.5rem;
      padding-bottom: 3rem;
      padding-left: 7rem;
      padding-right: 3rem;
    }
  }
  .bonshine-border{
    width: 525px;
  }
}
@media(min-width: 992px){
  #ein, #ingredient-matcher, #softkinetic, #ads, #opal-online{
    .menus{
      .right-side{
        max-width: 42rem;
        padding-right: 0;
      }
    }
  }
  #els, #kps, #ept, #eow, #cinamon, #rn, #ingredient-matcher, #svs, #etex, #softkinetic, #ads, #opal-online, #lb, #cube, #servers, #darlite, #fom, #bonshine, #et, #amber, #tapbox, #bi{
    .top-info{
      img{
        margin-bottom: -5.5rem;
      }
    }
  }
  #ads {
    .rules {
      .pull-left-little {
        margin-left: -2.8rem;
        margin-top: -35px;
      }
      .texts{
        margin-top: 30px;
      }
    }
  }
  .image-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
  }
  .infa-right{
    left: 72px;
  }
  .infa-left{
    right: 78px;
  }
  #fom{
    .offers{
      .top{
        img{
          margin-top: 1.2rem;
        }
      }
    }
  }
}

@media(min-width: 1200px){
  #els, #ein{
    .top-info{
      h4{
        margin-top: 4rem;
        margin-bottom: 4rem;
      }
    }

  }
  #els{
    .top-info{
      img{
        max-width: 540px;
        max-height: 342px;
      }
    }

  }
  #ein{
    .top-info {
      img {
        max-width: 44vw;
        max-height: 345px;
        margin-bottom: -5.5rem;
      }
    }
    .menus{
      .right-side{
        padding-left: 0;

      }
    }
  }
  #eow{
    .top-info{
      .arrow{
        position: absolute;
        bottom: -35px;
        left: -50px;
      }
    }
    .bus{
      right: -15rem;
    }
  }
  #svs{
    .right-side{
      padding-top: 5rem;
    }
  }
  #etex{
    .offers{
      p{
        max-width: 86rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  #ads{
    .right-side{
      padding-top: 2rem;
    }
    .rules{
      .pull-left-little{
        margin-left: -3.6rem;
        margin-top: -45px;
      }
      .texts{
        margin-top: 39px;
      }
    }
  }
  #els, #ein, #ept, #eow, #kps, #cinamon, #rn, #svs, #etex, #softkinetic, #ads, #opal-online, #fom, #tapbox, #bi{
    .top-info {
      p{
        margin-bottom: 4rem;
      }
    }
  }
  #fom{
    .text-center{
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  #amber{
    .top-info{
      h2, h4, p{
        max-width: 580px;
      }
      h2{
        margin-top: 0;
      }
    }
  }
  #bi{
    .offers{
      h2, p{
        max-width: 830px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}