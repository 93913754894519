#technology{
  margin-bottom: 130px;
  padding-top:100px;
  .more {
    margin-top: -21px;
  }
}

.technology-wrap{
  margin-top: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}
.technology{
  height: 195px;
  width: 20%;
  padding: 2rem;
  p{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.42;
    letter-spacing: 0.3px;
    text-align: left;
    color: #c9c9c9;
  }
  img{
    max-width: 60%;
    max-width: calc(100% - 4rem);
    max-height: 90px;
    border: 0;
    margin: auto;
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.technology .icon{
  width: 77px;
  height: 70px;
  display: inline-block;
  margin-bottom: 25px;
}
#extended-technology{
  img{
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  .title{
    margin-top: 115px;
    margin-bottom: 20px;
  }
}

.technologies-wrap{
  margin-bottom: 10rem;
  h4{
    margin-top: 43px;
    font-weight: bold;
    line-height: 1.33;
    color: #263754;
    margin-bottom: 20px;
    &:first-of-type{
      margin-top: 10px;
    }
  }
  p{
    font-size: 12px;
    line-height: 1.67;
    text-align: justify;
    color: #6b6b6b;
    margin-bottom: 0;
  }
  .gray-bg{
    background-color: #f7f7f7;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    height: 100%;
  }
}
@media ( max-width: 991px) {
  #technology{
    .short-text {
      margin-bottom: 5rem;
    }
  }
  .technology {
    height: 15rem;
    padding: 1.5rem;
  }
}
@media (max-width: 767px){
  .technology {
    width: 50%;
  }
  .technology:nth-of-type(9){
    border: none;
  }
  .technology-wrap {
    .first, .second, .third, .fourth, .fifth, .sixth, .seventh, .eight {
      border-bottom: 1px solid #e5e5e5;
    }
    .first, .third, .fifth, .seventh, .nineth {
      border-right: 1px solid #e5e5e5;
    }
  }
}
@media (min-width: 768px){
  .technology-wrap{
    .first, .second, .third, .fourth, .sixth, .seventh, .eight, .nineth{
      border-right: 1px solid #e5e5e5;
    }
    .first, .second, .third, .fourth, .fifth{
      border-bottom: 1px solid #e5e5e5;
    }
  }
  .technologies-wrap{
    .block{
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .gray-bg{

    }
  }
  #extended-technology .title {
    font-size: 48px;
  }
}
.technology:last-of-type{
  border: none;
}