#zeimuls{
  .title{
    margin-bottom: 37px;
  }
  margin-bottom: 70px;
  .top-info{
    background: url(../images/zeimuls/top-bg.jpg) center center no-repeat;
    background-size: 100% 100%;
    .brief-info-container{
      padding-bottom: 50px;
    }
    .brief-info{
      div{
        height: 40px;
      }
    }
  }
  #site_structure{
    background: url(../images/zeimuls/background.png) center top no-repeat;
    background-size: cover;
    padding-bottom: 75px;
  }
  .info-item-wrap{

    text-align: center;
    margin-top: 50px;
    padding: 20px;
  }
  .zeimuls-structure-item{
    display: inline-block;
    width: 230px;
    margin: 0px auto;
    height: 300px;
    vertical-align: top;
    margin-bottom: 15px;
  }
  img{
    max-width: 100%;
  }
  .development-text{
    margin-top: 65px;
  }
  h1{
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .development_process h1{
    margin-bottom: 0px;
  }
  .zeimuls-structure-item-title{
    font-weight: bold;
  }
}
@media ( min-width: 768px) {
  #zeimuls{
    margin-top: -100px;
    .top-info {
      padding-top: 130px;
    }
  }
}