.diagonal-top {
  color: #fff;
  background: #582325;
  transform:  rotate(357deg) scaleX(1.002) skew(-3deg) translateY(0px);
  -webkit-transform:  rotate(357deg) scaleX(1.002) skew(-3deg) translateY(0px);
  -moz-transform: rotate(357deg) scaleX(1.002) skew(-3deg) translateY(0px);
  -o-transform:  rotate(357deg) scaleX(1.002) skew(-3deg) translateY(0px);
  -ms-transform:  rotate(357deg) scaleX(1.002) skew(-3deg) translateY(0px);
}

.clearfix {
  padding-top: 45px;
  padding-bottom: 25px;
  transform:  rotate(3deg) scale(1) skew(3deg) translate(0px);
  -webkit-transform: rotate(3deg) scale(1) skew(3deg) translate(0px);
  -moz-transform: rotate(3deg) scale(1) skew(3deg) translate(0px);
  -o-transform:  rotate(3deg) scale(1) skew(3deg) translate(0px);
  -ms-transform:  rotate(3deg) scale(1) skew(3deg) translate(0px);
}


#museum{
  img{
    max-width: 100%;
  }
  color: rgb(78, 78, 78);
  .info{
    height: 40px;
  }
  h1{
    margin-top: 60px;
    margin-bottom: 50px;
    color: #582325;
  }
  .top-info{
    padding-top: 80px;
    height: 480px;
    background: url(../images/museum/museum_background.jpg) center center no-repeat;
    background-size: cover;

    .title{
      max-width: 780px;
      margin: 0 auto 30px;
      transition: all 0.1s;
    }
  }
  #partners, #games{
    img{
      float: left;
      z-index: 2;
      position: relative;
    }
  }
  .country{
    font-style: italic;
  }
  .list-item{
    margin-bottom: 60px;
  }
  .list-item2{
    margin-bottom: 45px;
  }
  .list-item3{
    margin-bottom: 20px;
    overflow: hidden;
  }
  .icon{
    margin-right: 30px;
  }
  .youtube{
    iframe{
      width: 100%;
      height: 600px;
    }
  }
  .virtual-tour{
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  #partners, #games{
    .unity, .csharp{
      text-align: center;
      height: 69px;
      margin-top: 30px;
      margin-bottom: 30px;
      img{
        float: none;
      }
    }
  }
  
  .note{
    position: relative;
    top: 10px;
    z-index: 1;
    float: left;
    font-size: 12px;
    height: 72px;
    width: 190px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px;
    color: white;
    background-color: #582325;
    line-height: 1.2;
    opacity: 0;
    margin-left: -190px;

  }
  .animated.note {
    margin-left: 0px;
    opacity: 1;
  }


  .note{
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }
}
@media ( min-width: 768px) and ( max-width: 991px ){
  //.technology-wrap{
  //  display: -webkit-box;
  //  display: -moz-box;
  //  display: -ms-flexbox;
  //  display: -webkit-flex;
  //  display: flex;
  //  .technology {
  //    width: 100%;
  //  }
  //}
}
@media ( min-width: 768px) {
  #museum{
    margin-top: -100px;
    .top-info {
      padding-top: 130px;
      height: 530px;
    }
  }
}
@media ( min-width: 992px) and ( max-width: 1199px ) {
  .image-holder{

  }
  .text-holder{

  }
}
@media ( max-width: 991px) {
  #museum{
    .youtube iframe {
      width: 100%;
      height: 370px;
    }
  }

}
@media ( max-width:600px) {
  #museum .animated.note {
    margin-left: 0;
    opacity: 1;
  }
  #museum .note {
    transition: all 0s;
  }
}
@media ( max-width: 450px) {
  #museum{
    .youtube iframe {
      height: 250px;
    }
    .unity, .csharp{
      margin-bottom: 0px;
      img{
        max-width: 75%;
      }
    }
  }
}